<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-9 px-0">
              <div class="col-4 pr-0">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    :placeholder="$t('goodwemonitoring.placeholder.search')"
                    v-model="search"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>

              <div class="col-4">
                <vs-select
                  v-if="deviceTypes"
                  v-model="searchDeviceType"
                  :options="deviceTypes"
                  :placeholder="
                    $t(
                      'goodwemonitoring.conditions.placeholder.search_device_type'
                    )
                  "
                  label="name"
                />
              </div>

              <b-button
                variant="secondary"
                class="mr-3 ml-3 pr-2"
                @click.prevent="removeFilters"
              >
                <i class="fa fa-times icon-md"></i>
              </b-button>
              <b-button variant="primary" @click.prevent="searchConditions">
                {{ $t("button.filter") }}
              </b-button>
            </div>
            <div class="card-toolbar">
              <b-button
                variant="success"
                v-b-modal.modal-condition-edit
                @click="unsetCondition()"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                </span>
                {{ $t("goodwemonitoring.conditions.button.create") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th width="20"></th>
                <th>{{ $t("goodwemonitoring.conditions.title.name") }}</th>
                <th>
                  {{ $t("goodwemonitoring.conditions.title.device_type") }}
                </th>
                <th class="text-center">
                  {{ $t("goodwemonitoring.conditions.title.device_count") }}
                </th>
                <th class="text-center">
                  {{ $t("goodwemonitoring.conditions.title.last_check") }}
                </th>
                <th class="text-center">
                  {{ $t("goodwemonitoring.conditions.title.mail_alert") }}
                </th>
                <th width="120" class="text-center">
                  {{ $t("goodwemonitoring.conditions.title.status") }}
                </th>
                <th width="90"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(condition, index) in conditions.data" :key="index">
                <td class="pl-0">
                  <router-link
                    :to="{
                      name: 'goodwemonitoring_conditions_detail',
                      params: { id: condition.custom_filter_id },
                    }"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg src="/media/svg/icons/General/Search.svg" />
                    </span>
                  </router-link>
                </td>

                <td>
                  <span
                    v-if="condition.filter == '' || condition.filter == null"
                    class="svg-icon svg-icon-xl svg-icon-warning mr-4"
                    v-b-tooltip.right="
                      $t('goodwemonitoring.conditions.title.fill_condition')
                    "
                  >
                    <inline-svg src="/media/svg/icons/Code/Warning-2.svg" />
                  </span>
                  <span class="font-weight-bolder">{{condition.name}}</span>
                </td>

                <td>
                  <span>
                    {{ $t("goodwemonitoring.conditions.title." + condition.type) }}
                  </span>
                </td>

                <td class="text-danger font-weight-bolder" align="center">
                  <b-badge variant="danger" v-if="condition.last_result > 0">{{condition.last_result}}</b-badge>
                  <b-badge variant="success" v-else>0</b-badge>
                </td>

                <td align="center">
                  <span v-if="condition.last_check_at == null" class="text-muted">-</span>
                  <span v-else>{{condition.last_check_at | moment("D. M. YYYY HH:MM")}}</span>
                </td>

                <td align="center">
                  <span 
                    v-if="condition.email_report == 1"
                    class="svg-icon svg-icon-xl svg-icon-primary">
                    <inline-svg
                      src="/media/svg/icons/Communication/Readed-mail.svg"
                    />
                  </span>
                </td>

                <td align="center">
                  <span>
                    <b-badge variant="danger" v-if="condition.is_active == 0">{{$t("goodwemonitoring.conditions.title.unactive")}}</b-badge>
                    <b-badge variant="success" v-if="condition.is_active == 1">{{ $t("goodwemonitoring.conditions.title.active") }}</b-badge>
                  </span>
                </td>

                <td>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-danger
                      btn-sm
                      btn-icon
                    "
                    v-b-modal.modal-condition-delete
                    @click="setCondition(condition)"
                    ><i class="ki ki-bold-close icon-sm text-danger"></i
                  ></a>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-primary
                      btn-sm
                      btn-icon
                    "
                    v-b-modal.modal-condition-edit
                    @click="setCondition(condition)"
                    ><i class="ki ki-gear icon-md"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination :data="conditions.meta" v-if="!loading"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>

    <b-modal
      id="modal-condition-delete"
      :okTitle="$t('button.delete')"
      okVariant="danger"
      :title="
        $t('goodwemonitoring.conditions.title.confirm_delete_condition_title')
      "
    >
      <span
        v-html="
          $t('goodwemonitoring.conditions.title.confirm_delete_condition_text')
        "
      ></span>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="danger" @click="deleteCondition(selectedCondition)">
          {{ $t("goodwemonitoring.conditions.button.delete_condition") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-condition-edit"
      :okTitle="$t('button.save')"
      okVariant="success"
      :title="$t('goodwemonitoring.conditions.title.edit_condition')"
    >
      <ValidationObserver ref="form">
        <ValidationProvider
          name="name"
          rules="required|min:3"
          v-slot="{ errors }"
        >
          <span class="text-muted mb-2 d-block">{{
            $t("goodwemonitoring.conditions.label.name")
          }}</span>
          <input
            v-model="selectedCondition.name"
            type="text"
            class="form-control form-control-lg form-control-solid"
            :placeholder="$t('goodwemonitoring.conditions.placeholder.name')"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider
          name="name"
          rules="required|min:3"
          v-slot="{ errors }"
        >
          <span class="text-muted mb-2 d-block mt-5">{{
            $t("goodwemonitoring.conditions.label.description")
          }}</span>
          <textarea
            v-model="selectedCondition.description"
            class="form-control form-control-lg form-control-solid"
            :placeholder="
              $t('goodwemonitoring.conditions.placeholder.description')
            "
          ></textarea>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <hr />

        <span class="text-muted mb-2 mt-5 d-block">{{
          $t("goodwemonitoring.conditions.label.device_type")
        }}</span>
        <vs-select
          v-if="deviceTypes"
          v-model="selectedCondition.type"
          :options="deviceTypes"
          :placeholder="
            $t('goodwemonitoring.conditions.placeholder.search_device_type')
          "
          label="name"
        />

        <span class="text-muted mb-2 mt-5 d-block">{{
          $t("goodwemonitoring.conditions.label.email_reporting")
        }}</span>
        <b-form-checkbox
          v-model="selectedCondition.email_report"
          name="email_reporting"
          switch
          size="l"
          class="d-inline"
          value="1"
          unchecked-value="0"
          >{{
            $t("goodwemonitoring.conditions.label.email_reporting")
          }}</b-form-checkbox
        >

        <span class="text-muted mb-2 mt-5 d-block">{{
          $t("goodwemonitoring.conditions.label.is_active")
        }}</span>
        <b-form-checkbox
          v-model="selectedCondition.is_active"
          name="is_active"
          switch
          size="l"
          class="d-inline"
          value="1"
          unchecked-value="0"
          >{{
            $t("goodwemonitoring.conditions.label.is_active")
          }}</b-form-checkbox
        >
        <hr class="mt-5" />

        <p>
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/Code/Info-circle.svg" />
          </span>
          {{ $t("goodwemonitoring.conditions.title.condition_info_text") }}
        </p>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="success" @click="updateCondition(selectedCondition)">
          {{ $t("goodwemonitoring.conditions.button.save_condition") }}
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Pagination from "@/view/content/Pagination";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      loading: false,
      conditions: null,
      selectedCondition: {},
      page: 1,
      search: "",
      searchDeviceType: null,
      deviceTypes: ["GRID", "BATTERY"],
    };
  },
  components: {
    Pagination,
  },
  beforeMount() {
    this.getConditions();
  },
  metaInfo() {
    return { title: this.$t("goodwemonitoring.meta.conditions_list") };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("goodwemonitoring.meta.conditions"),
        subtitle: this.$t("goodwemonitoring.meta.conditions_list"),
      },
    ]);
  },
  methods: {
    getConditions() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/goodwe-monitoring/custom-filters?page=" + this.page + "&perpage=" + this.perpage + "&search=" + this.search + "&type=" + this.searchDeviceType)
        .then((response) => {
          this.conditions = response.data;
          this.loading = false;
        });
    },
    setCondition(condition) {
      this.selectedCondition = condition;
    },
    unsetCondition() {
      this.selectedCondition = {
        is_active: 1,
        email_report: 1
      };
    },
    deleteCondition(condition) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/goodwe-monitoring/custom-filters/" + condition.custom_filter_id + "/delete"
      )
        .then(() => {
          this.loading = false;
          this.$bvModal.hide("modal-condition-delete");
          this.getConditions();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    updateCondition(condition) {
      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        } 

        if(condition.custom_filter_id){
          this.endpoint = "/goodwe-monitoring/custom-filters/" + condition.custom_filter_id + "/update"
        } else {
          this.endpoint  = "/goodwe-monitoring/custom-filters";
        }

        ApiService.setHeader();      
        ApiService.apiPost(this.endpoint,{
          'name': condition.name,
          'description': condition.description,
          'type': condition.type,
          'is_active': condition.is_active,
          'email_report': condition.email_report,
        })
          .then(() => {
              this.loading = false;
              this.$bvModal.hide('modal-condition-edit');
              this.getConditions();
          }).catch(error => {
              checkErrors(error);
        });
      });
    },
    searchConditions() {
      this.page = 1;
      this.getConditions();
    },
    removeFilters() {
      this.page = 1;
      this.search = "";
      this.searchDeviceType = null;
      this.getConditions();
    },
  },
};
</script>
